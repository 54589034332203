<template>
  <!--begin::Accordion-->
  <div
    class="accordion accordion-light accordion-light-borderless accordion-svg-toggle"
    id="accGTM"
  >
    <!--begin::Item-->
    <div class="card">
      <!--begin::Header-->
      <div class="card-header" id="headingOne1">
        <div
          v-b-toggle.collapseOne1
          variant="primary"
          class="card-title"
          aria-expanded="true"
          role="button"
        >
          <span class="svg-icon svg-icon-primary">
            <!--begin::Svg Icon | path:/media/svg/icons/Navigation/Angle-double-right.svg-->
            <inline-svg
              src="media/svg/icons/Navigation/Angle-double-right.svg"
            ></inline-svg>
            <!--end::Svg Icon-->
          </span>
          <div class="card-label text-dark pl-4">
            Passo 1 – Baixe o plugin
          </div>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <b-collapse
        id="collapseOne1"
        class="collapse show"
        aria-labelledby="headingOne1"
        data-parent="#accGTM"
      >
        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>
            a) Para instalar o plugin Consensu.io no WordPress usando o FTP ou
            SFT, baixe o ZIP no endereço abaixo:
          </p>
          <p>
            <strong>Link do Plugin Wordpress:</strong><br />
            <a
              href="https://consensu.io/plugins/wordpress/consensu-lgpd.zip"
              target="_blank"
              >https://consensu.io/plugins/wordpress/consensu-lgpd.zip
            </a>
          </p>
        </div>
        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>
            b) Descompacte o arquivo em um diretório de sua preferência.
          </p>
        </div>
        <div class="rounded-x1 overflow-hidden w-100 max-h-md-500px  pl-12">
          <img
            src="/media/integrations/wordpress/passo1.jpg"
            class="w-100"
            alt=""
          />
        </div>
      </b-collapse>
      <!--end::Body-->
    </div>
    <!--end::Item-->
    <!--begin::Item-->
    <div class="card">
      <!--begin::Header-->
      <div class="card-header " id="headingOne2">
        <div
          class="card-title"
          v-b-toggle.collapseOne2
          aria-expanded="true"
          role="button"
        >
          <span class="svg-icon svg-icon-primary">
            <!--begin::Svg Icon | path:/media/svg/icons/Navigation/Angle-double-right.svg-->
            <inline-svg
              src="media/svg/icons/Navigation/Angle-double-right.svg"
            ></inline-svg>

            <!--end::Svg Icon-->
          </span>
          <div class="card-label text-dark pl-4">
            Passo 2 – Envie os Arquivos Para o Servidor
          </div>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <b-collapse
        id="collapseOne2"
        class="collapse show"
        aria-labelledby="headingOne2"
        data-parent="#accGTM"
      >
        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>
            a) Conecte-se à sua conta de hospedagem usando um cliente FTP e
            navegue até o diretório do raiz do WordPress.
          </p>
        </div>
        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>
            b) Localize o diretório “wp-content” e dentro dele o diretório
            “plugins”.
          </p>
        </div>
        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>
            c) Copie a o diretório descompactado “” com os arquivos extraídos do
            ZIP para dentro do diretório “plugins” em seu servidor.
          </p>
        </div>
        <div
          class="card-body rounded-x1 overflow-hidden w-100 max-h-md-500px  pl-12"
        >
          <img
            src="/media/integrations/wordpress/passo2.jpg"
            class="w-100"
            alt=""
          />
        </div>
      </b-collapse>
      <!--end::Body-->
    </div>
    <!--end::Item-->

    <!--begin::Item-->
    <div class="card">
      <!--begin::Header-->
      <div class="card-header" id="headingOne3">
        <div
          class="card-title collapsed"
          v-b-toggle.collapseOne3
          aria-expanded="false"
          role="button"
        >
          <span class="svg-icon svg-icon-primary">
            <!--begin::Svg Icon | path:/media/svg/icons/Navigation/Angle-double-right.svg-->
            <inline-svg
              src="media/svg/icons/Navigation/Angle-double-right.svg"
            ></inline-svg>
            <!--end::Svg Icon-->
          </span>
          <div class="card-label text-dark pl-4">
            Passo 3 – Ative o Plugin
          </div>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <b-collapse
        id="collapseOne3"
        class="collapse show"
        aria-labelledby="headingOne3"
        data-parent="#accGTM"
      >
        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>
            a) Através da área administrativa do Wordpress, clique sobre a seção
            Plugins e selecione o plugin “Consensu.io | Conformidade e
            Consentimento de Cookies para LGPD” e clique em ativar “Ativar”
          </p>
        </div>
        <div
          class="card-body rounded-x1 overflow-hidden w-100 max-h-md-500px  pl-12"
        >
          <img
            src="/media/integrations/wordpress/passo3.jpg"
            class="w-100"
            alt=""
          />
        </div>
      </b-collapse>
      <!--end::Body-->
    </div>
    <!--end::Item-->
    <!--begin::Item-->
    <div class="card">
      <!--begin::Header-->
      <div class="card-header" id="headingOne4">
        <div
          class="card-title collapsed"
          v-b-toggle.collapseOne4
          aria-expanded="false"
          role="button"
        >
          <span class="svg-icon svg-icon-primary">
            <!--begin::Svg Icon | path:/media/svg/icons/Navigation/Angle-double-right.svg-->

            <inline-svg
              src="media/svg/icons/Navigation/Angle-double-right.svg"
            ></inline-svg>

            <!--end::Svg Icon-->
          </span>
          <div class="card-label text-dark pl-4">
            Passo 4 – Configure o plugin
          </div>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <b-collapse
        id="collapseOne4"
        class="collapse show"
        aria-labelledby="headingOne4"
        data-parent="#accGTM"
      >
        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>
            a) Selecione o item “LGPD - Consensu” no menu lateral da área
            administrativa do Wordpress
          </p>
        </div>
        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>
            b) No campo Chave de Identificação de Cliente insira o seu código de
            cliente e clique em "Salvar Alterações".
          </p>
        </div>
        <div
          class="card-body rounded-x1 overflow-hidden w-100 max-h-md-500px  pl-12"
        >
          <img
            src="/media/integrations/wordpress/passo4.jpg"
            class="w-100"
            alt=""
          />
        </div>
        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>
            c) Voce pode encontrar sua chave de cliente através do painel
            Consensu, selecionado "Sites" e depois sobre o icone "" no website
            desejado.
          </p>
        </div>
        <div
          class="card-body rounded-x1 overflow-hidden w-100 max-h-md-500px  pl-12"
        >
          <img
            src="/media/integrations/wordpress/passo4_1.jpg"
            class="w-100"
            alt=""
          />
        </div>
        <div
          class="card-body rounded-x1 overflow-hidden w-100 max-h-md-500px  pl-12"
        >
          <img
            src="/media/integrations/wordpress/passo4_2.jpg"
            class="w-100"
            alt=""
          />
        </div>
      </b-collapse>
      <!--end::Body-->
    </div>
    <!--end::Item-->
    <!--begin::Item-->
    <div class="card">
      <!--begin::Header-->
      <div class="card-header" id="headingOne5">
        <div
          class="card-title collapsed"
          v-b-toggle.collapseOne5
          aria-expanded="false"
          role="button"
        >
          <span class="svg-icon svg-icon-primary">
            <!--begin::Svg Icon | path:/media/svg/icons/Navigation/Angle-double-right.svg-->

            <inline-svg
              src="media/svg/icons/Navigation/Angle-double-right.svg"
            ></inline-svg>

            <!--end::Svg Icon-->
          </span>
          <div class="card-label text-dark pl-4">
            Passo 5 - Valide sua instalação
          </div>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <b-collapse
        id="collapseOne5"
        class="collapse show"
        aria-labelledby="headingOne5"
        data-parent="#accGTM"
      >
        <div class="card-body text-dark-50 font-size-lg pl-12">
          <p>
            Entre em seu website Wordpress e verifique se o box de consentimento
            é apresentado
          </p>
        </div>
        <div
          class="card-body rounded-x1 overflow-hidden w-100 max-h-md-500px  pl-12"
        >
          <img
            src="/media/integrations/wordpress/Passo5.jpg"
            class="w-100"
            alt=""
          />
        </div>
      </b-collapse>
      <!--end::Body-->
    </div>
    <!--end::Item-->
  </div>
  <!--end::Accordion-->
</template>

<script>
export default {
  name: "integration-wordpress"
};
</script>

<style></style>
